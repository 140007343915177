import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data("stork_info", () => ({
  region: 2,
  factbox: {},
  isLoaded: false,
  init() {
    this.loadFactbox();
  },
  async loadFactbox() {
    let response = await fetch("https://stork.tvmv.dk/factbox/?region=" + this.region);
    let json = await response.json();
    
    this.factbox = json.data[0];
    this.isLoaded = true;
  },

  timestampFormat(timestamp) {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };
    return (new Date(timestamp)).toLocaleDateString('da-DA', options);
  }
}));