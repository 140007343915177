import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('latest_news', () => ({
  current: 1,
  slides: 12,
  firstSlide: 1,
  lastSlide: 12,
  gap: 0,
  init () {
    this.resizeHandler({}, true);
  },
  isAppRequest () {
    return window.navigator.userAgent.indexOf('median') !== -1;
  },
  resizeHandler (evt, init) {
    this.gap = this.calcGap(this.$refs.viewport.firstElementChild);
    const viewportWidth = this.$refs.viewport.clientWidth;
    const scrollWidth = this.$refs.viewport.scrollWidth;
    const totalGapWidth = (this.slides - 1) * this.gap;
    this.slideWidth = (scrollWidth - totalGapWidth) / this.slides;
    this.slidesPerView = Math.floor(viewportWidth / (this.slideWidth + this.gap));
    this.centeringOffset = ((viewportWidth - this.slideWidth) / 2);
    this.firstSlide = 1 + Math.floor(this.slidesPerView / 2);
    this.lastSlide = this.slides - Math.floor(this.slidesPerView / 2);
    if (init) {
      this.current = this.firstSlide;
    }
    this.slideAmount = this.slideWidth + this.gap;
    this.slideTo(this.current);
  },
  calcGap (container)  {
    if (container.children.length < 2) return 0;
    const e1rect = container.children[0].getBoundingClientRect();
    const e2rect = container.children[1].getBoundingClientRect();
    const e1left = e1rect.left;
    const e1width = e2rect.width;
    const e2left = e2rect.left;
    const gap = e2left - (e1left + e1width);
    return gap;
  },
  scrollHandler (evt) {
    let scroll = evt.target.scrollLeft;
    let current = 1;
    if (scroll + 10 < this.slideAmount * (this.firstSlide) - this.centeringOffset) {
      current = this.firstSlide;
    } else if (scroll - 10 > this.slideAmount * (this.lastSlide - 2) - this.centeringOffset) {
      current = this.lastSlide;
    } else {
      current = Math.round((scroll + this.centeringOffset) / this.slideAmount + 1);
    }
    this.current = current;
  },
  slideTo (num) {
    if (num < this.firstSlide || num > this.lastSlide) return;
    let left = 0;
    if (num != this.firstSlide) {
      left = this.slideAmount * (num - 1) - this.centeringOffset;
    }
    this.$refs.viewport.scrollTo({
      left: left,
      behavior: "smooth",
    });
  }
}));