import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('webscreen', () => ({
  activeIndex: 0,
  changesBeforeRefresh: 16,
  maxEntries: 8,
  init() {
    setInterval(() => {
      this.changeIndex();
    }, 20000)
  },
  changeIndex() {
    if(this.activeIndex === (this.maxEntries - 1)) {
      this.activeIndex = 0;
    } else {
      this.activeIndex++;
    }
    this.changesBeforeRefresh--;
    if(this.changesBeforeRefresh === 0){
      window.location.reload();
    }
  }
}))