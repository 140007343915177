import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('swiper_helper', (swiperSettings, firstSlideMargin = []) => ({
  swiperSettings: swiperSettings,
  swiperElement: null,
  minActiveIndex: 0,
  firstSlideMargin: firstSlideMargin,
  init() {
    this.swiperElement = this.$el;
    Object.assign(this.swiperElement, this.swiperSettings);
    this.swiperElement.initialize();
    
    if(this.firstSlideMargin.length > 0) {
      this.calculateMinActiveIndex();
      window.addEventListener('resize', this.calculateMinActiveIndex.bind(this));
      this.firstElementMarginOffset();
      this.swiperElement.swiper.on('slideChange', this.firstElementMarginOffset.bind(this));
    }

  },
  calculateMinActiveIndex() {
    const breakPoints = Object.keys(this.swiperSettings.breakpoints);

    let minActiveIndex = 0;
    if(breakPoints.length > 0 && window.innerWidth >= breakPoints[0]) {
      const closestBreakPoint = breakPoints.find((breakpoint, index, array)  => {
        if(array[index + 1] !== undefined) {
          return window.innerWidth >= breakpoint && array[index + 1] > window.innerWidth;
        } else {
          return window.innerWidth >= array[array.length - 1];
        }
      })
      minActiveIndex = Math.max(1,Math.floor(this.swiperSettings.breakpoints[closestBreakPoint].slidesPerView / 2));
    }
    this.minActiveIndex = minActiveIndex;
  },
  firstElementMarginOffset() {
    if(this.swiperElement.swiper.activeIndex == this.minActiveIndex) {
      for(let i = 0; i < this.firstSlideMargin.length; i++) {
        this.swiperElement.firstElementChild.classList.add(this.firstSlideMargin[i])
      }
    } else {
      for(let i = 0; i < this.firstSlideMargin.length; i++) {
        this.swiperElement.firstElementChild.classList.remove(this.firstSlideMargin[i])
      }
    }
  }
}));