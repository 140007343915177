import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data("textbox", () => ({
  isOpen: false,
  init() {
    this.isOpen = !this.$el.dataset.fold;
  },
  openTextBox() {
    this.isOpen = !this.isOpen;
  }
}));