import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data("stork_form", () => ({
  recaptcha_sitekey: '6Lf9aHEaAAAAAO90ede9XzRWXoPojUtT5xdbo_Lr',
  region: 2,
  form: {
    email: null,
    username: null,
    location: null,
    question: null,
    robot: false,
    region: 2,
    captcha_response: false,
  },
  showUIMessage: null,
  async submitForm() {
    this.showUIMessage = null;

    this.form.captcha_response = grecaptcha.getResponse();
    this.form.robot = (this.form.captcha_response !== "");

    if(this.form.robot) {

      let response = await fetch('https://stork.tvmv.dk/submit/', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.form),
      })

      let value = response.json();

      value.then((data) => {
        this.form.email = null;
        this.form.username = null;
        this.form.location = null;
        this.form.question = null;
        this.form.robot = false;
        this.form.captcha_response = false;
        this.showUIMessage = data === true ? 'success' : 'error';
        grecaptcha.reset();
      })

    } else {
      this.showUIMessage = 'error';
    }

  }
}));