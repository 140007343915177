import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('large_video', () => ({
  init() {
    // Set object-fit: cover on the video element inside of the shadowRoot
    let innerShadowRootVideo = this.$refs.large_video.shadowRoot.host.shadowRoot.lastChild;
    if(innerShadowRootVideo != null && innerShadowRootVideo != undefined) {
      innerShadowRootVideo.style = "object-fit: cover;"
    }
  },
  start() {
    this.$refs.large_video.play();
  },
  stop() {
    this.$refs.large_video.pause();
  }
}))