import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data("stork_answers", (maxEntries, showLoadMore) => ({
  region: 2,
  maxEntries: maxEntries,
  enableLoadMoreBtn: showLoadMore,
  page: 1,
  answeredQuestions: [],
  init() {
    this.loadAnswers();
  },
  async loadAnswers() {
    let response = await fetch("https://stork.tvmv.dk/answer/?page=" + this.page + "&region=" + this.region + "&maxEntries=" + this.maxEntries);
    let json = await response.json();
    this.answeredQuestions = [...this.answeredQuestions, ...json.data];
    this.page++;
  },
  loadMoreAnswers() {
    if(this.enableLoadMoreBtn) {
      this.loadAnswers();
    }
  },
  timestampFormat(timestamp) {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };
    return (new Date(timestamp)).toLocaleDateString('da-DA', options);
  }
}));