import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('chartbeat', (url, headline, sections) => ({
  url: url,
  headline: headline,
  sections: sections,
  track() {
    pSUPERFLY.virtualPage({
      sections: this.sections,
      authors: "",
      path: this.url,
      title: this.headline
    });
  }
}));
