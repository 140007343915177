import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('external_quiz', (id) => ({
  id: id,
  init() {
    window.addEventListener('message', event => {
      if (event.origin === 'https://external.tvsyd.dk') {
        if (event.data.frame_id === 'quiz_id') {
          if (event.data.height && Number.isInteger(event.data.height)) {
            this.resize_frame(event.data.height);
          }
        }
      }
    });
  },
  resize_frame(nh) {
    this.$refs.quiz_iframe.height = nh + "px";
  },
  quizUrl() {
    return 'https://external.tvsyd.dk/toolbox/quiz/quiz.php?id=' + this.id;
  }
}));
