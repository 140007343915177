import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('reelsplayer', (id, playing) => ({
  id: id,
  playing: playing,
  player: null,
  muted: true,
  init () {
    if (!window.frop_players) window.frop_players = [];
    if (!window.frop_players[this.id]) window.frop_players[this.id] = () => {
      this.player = window.frop_players[this.id];
      if (!this.playing) this.pause();
      this.player.on('time', (e) => {
        const width = `${e.position / this.player.getDuration() * 100}%`;
        this.$refs.progress1.style.width = width;
        this.$refs.progress2.style.width = width;
      });
      this.muted = this.player.getMute();
      this.player.on('mute', (e) => {
        this.muted = e.mute;
      });
    }
  },
  play () {
    if (!this.player) return;
    this.player.play();
},
  pause () {
    if (!this.player) return;
    this.player.pause();
  },
  play_pause () {
    const state = this.player.getState();
    if (state == 'playing') {
      this.pause();
    } else if (state == 'paused' || state == 'idle') {
      this.play();
    }
  },
  skip (amount) {
    this.player.seek(this.player.getPosition() + amount);
  },
  mute () {
    this.player.setMute(true);
  },
  unmute () {
    this.player.setMute(false);
    this.player.setVolume(100);
  },
}));
