import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('imagecomparison', () => ({
  position: 50,
  width: '200%',
  init () {
    this.setWidth();
  },
  setWidth () {
    this.width = this.$refs.width_yard_stick.offsetWidth + 'px';
  },
  onInput (e) {
    this.position = e.target.value;
  },
  onResize (e) {
    console.log('resize');
    this.setWidth();
  }
}))