import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('push_message_dialog', () => ({
  website: "https://www.tvsyd.dk/",
  init () {
    document.addEventListener('DOMContentLoaded', function () {

      async function getPermissionStatus() {
          const result = await median.permissions.status(['Notifications']);
          const status = result['Notifications'];

          const device_info = await median.deviceInfo();

          console.log('Permission status:', status);
          console.log('Permission status:', JSON.stringify(device_info));

          if (status === 'denied' && device_info.os != 'Android') {
              document.getElementById('loadingMSG').style.display = 'none';
              document.getElementById('checkPushPreferencesMsg').style.display = 'none';
              document.getElementById('checkPushPreferencesWarnining').style.display = 'block';
          } else {
              document.getElementById('loadingMSG').style.display = 'none';
              document.getElementById('checkPushPreferencesWarnining').style.display = 'none';
              document.getElementById('checkPushPreferencesMsg').style.display = 'block';
          }
      }

      setInterval(getPermissionStatus, 1000);
    });
  },
  async pushyes() {

    function tagCallbackFunction(tagResult) {
        cosnole.log("Tags set successfully:", tagResult);
    }

    const onesignalTagsYesPush = {
        tags: {
            pushnews: 1
        },
        callback: tagCallbackFunction
    };

    try {
        await median.onesignal.userPrivacyConsent.grant();
        console.log("Privacy consent granted.");
    } catch (error) {
        console.error("An error occurred while granting privacy consent:", error);
    }

    try {
        await median.onesignal.register();
        console.log("Registration successful.");
    } catch (error) {
        console.error("An error occurred while registering:", error);
    }

    try {
        await median.onesignal.tags.setTags(onesignalTagsYesPush);
        console.log("Tags set successfully.");
    } catch (error) {
        console.error("An error occurred while setting tags:", error);
    }

    try {
        window.location.href = this.website;
        console.log("Redirect successful.");
    } catch (error) {
        console.error("An error occurred while redirecting:", error);
    }

  },
  pushNoOnWelcome() {
    window.location.href = this.website;
    console.log("User opted out of push notifications.");
  },
  async pushNoOnSettings() {

    function tagCallbackFunction(tagResult) {
        cosnole.log("Tags set successfully:", tagResult);
    }

    var onesignalTagsNoPush = {
        tags: {
            pushnews: 0
        },
        callback: tagCallbackFunction
    };

    try {
        await median.onesignal.userPrivacyConsent.grant();
        console.log("Privacy consent granted.");
    } catch (error) {
        console.error("An error occurred while granting privacy consent:", error);
    }

    try {
        await median.onesignal.tags.setTags(onesignalTagsNoPush);
        console.log("Tags set successfully.");
    } catch (error) {
        console.error("An error occurred while setting tags:", error);
    }

    try {
        window.location.href = this.website;
        console.log("Redirect successful.");
    } catch (error) {
        console.error("An error occurred while redirecting:", error);
    }
  }
}))
