import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('priorityScroll', (enableScrollTo) => ({
  enableScrollTo: enableScrollTo,  
  init() {
    if(this.enableScrollTo) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }, 100)
    }
  },
}));