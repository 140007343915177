import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('recipe', () => ({
  prepTime: 0,
  cookTime: 0,
  init() {
    let timeHTML = this.$el.children[3];
    let prepHTML = timeHTML.children[0];
    let cookHTML = timeHTML.children[1]

    this.prepTime = this.calculateSeconds(prepHTML.dataset.time);
    this.cookTime = this.calculateSeconds(cookHTML.dataset.time);
  },
  calculateSeconds(timeStr) {
    const timeArray = timeStr.split(",")
    let seconds = 0;
    // Index 0 is hours
    seconds += (parseInt(timeArray[0]) * 3600);
    // Index 1 is minutes
    seconds += (parseInt(timeArray[1]) * 60);
    // Index 2 is seconds
    seconds += parseInt(timeArray[2]);
    return seconds;
  },
  pluralize(number, noun) {
    if (number == 1) return noun;
    if (noun == 'time') return 'timer';
    if (noun == 'minut') return 'minutter';
    if (noun == 'sekund') return 'sekunder';
  },
  getReadableTime(totalSeconds){
    let hours = Math.floor(totalSeconds/3600);
    let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
    let seconds = totalSeconds - (hours * 3600) - (minutes * 60);

    let formattedArray = []
    let hideSeconds = false;

    if(hours) {
      formattedArray.push(`${hours} ${this.pluralize(hours, 'time')}`)
      hideSeconds = true;
    }
    if(minutes) {
      formattedArray.push(`${minutes} ${this.pluralize(minutes, 'minut')}`)
    }
    if(seconds && !hideSeconds) {
      formattedArray.push(`${seconds} ${this.pluralize(seconds, 'sekund')}`)
    }

    return formattedArray.join(" og ");
  },
  getPrepTime() {
    return this.getReadableTime(this.prepTime)
  },
  getCookTime(){
    return this.getReadableTime(this.cookTime)
  },
  getTotalTime() {
    return this.getReadableTime(this.prepTime + this.cookTime)
  }
}));