import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('details_button', () => ({
  isOpen: false,
  detailsElement: null,
  init() {
    this.detailsElement = this.$el;
  },
  checkOpenState() {
    this.$nextTick(() => {
      this.isOpen = this.detailsElement.open;
    })
  }
}));