import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data("tabs", () => ({
  activeTab: "",
  tabWrapper: false,
  init() {
    this.tabWrapper = this.$el.querySelectorAll('.tab-wrapper');
    if(this.tabWrapper.length === 0) {
      return;
    }
    this.tabWrapper = this.tabWrapper[0];
    
    if(window.location.hash != "") {
      this.activeTab = window.location.hash.substring(1);
    } else {
      this.activeTab = this.tabWrapper.children[0].dataset.slug;
    }
  },
  showTab(slug) {
    return this.activeTab == slug;
  },
  setActiveTab(slug) {
    this.activeTab = slug;
  }
}));