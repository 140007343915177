import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data("mosaik", () => ({
  currentID: null,
  isOpen() {
    return this.$el.dataset.id == this.currentID;
  },
  toggleSection() {
    this.currentID = (this.currentID === this.$el.dataset.id) ? null : this.$el.dataset.id;
  },
  setPosition() {
    const count = this.$el.dataset.count;
    
    let classStr = "";

    if(count % 2 === 0) {
      classStr = "right-[calc(100%+0.25rem)] "
    } else {
      classStr = "right-[-0.25rem] "
    }

    let decimals = (count / 3 - Math.floor(count / 3)).toFixed(1);
    
    if(decimals == 0.3) {
      classStr += "md:right-[-1rem]"
    }

    if(decimals == 0.7) {
      classStr += "md:right-[100%]"
    }

    if(decimals == 0.0) {
      classStr += "md:right-[calc(200%+1rem)]"
    }

    return classStr
  }
}));