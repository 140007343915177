import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('app_banner', (debug = false) => ({
  debug: debug,
  lsEnabledKey: 'app_banner_enabled',
  lsTTDKey: 'app_banner_ttd',
  daysUntilExpiry: 8,
  showBanner: false,
  currentDevice: 'ios',
  whitelistedDevices: [
    "iPhone",
    "Android",
    "iPad"
  ],
  whitelistedBrowsers: {
    android: [
      'Chrome',
      'Firefox',
      'EdgA',
    ],
    ios: [
      'Safari',
    ]
  },
  init() {

    if(this.debug) {
      this.showBanner = true;
      return;
    }

    let enabled = localStorage.getItem(this.lsEnabledKey)

    /*if(enabled == null) {*/
    const isValid = this.isValidUserAgent();
    localStorage.setItem(this.lsEnabledKey, isValid);
    enabled = isValid;
    /*}*/
    
    if(enabled === false || enabled == 'false') {
      return;
    }

    this.setShowBanner();
  },
  setShowBanner() {
    const ttd = localStorage.getItem(this.lsTTDKey)

    if(ttd == null) {
      localStorage.setItem(this.lsTTDKey, this.createTTD());
      this.showBanner = true;
      return;
    }

    if(this.isTTDExpired(ttd)) {
      localStorage.setItem(this.lsTTDKey, this.createTTD());
      this.showBanner = true;
      return;
    }

    this.showBanner = false;
  },
  isValidUserAgent() {

    const UA = window.navigator.userAgent;

    // Disable banner if the useragent is our app
    if(UA.indexOf('median') !== -1) {
      return false;
    }

    let validDevice = false;
    let currentDevice = null;

    for(let i = 0; i < this.whitelistedDevices.length; i++) {
      if(UA.indexOf(this.whitelistedDevices[i]) !== -1) {
        validDevice = true;
        currentDevice = this.whitelistedDevices[i];
        break;
      }
    }

    this.currentDevice = currentDevice === 'Android' ? 'android' : 'ios';

    if(!validDevice) {
      return false;
    }

    let validBrowser = false;
    for(let i = 0; i < this.whitelistedBrowsers[this.currentDevice].length; i++) {
      if(UA.indexOf(this.whitelistedBrowsers[this.currentDevice][i]) !== -1) {
        validBrowser = true;
        break;
      }
    }

    return validDevice && validBrowser;
  },
  isTTDExpired(value) {
    return (new Date()).getTime() > value;
  },
  createTTD() {
    return (new Date()).getTime() + (this.daysUntilExpiry * 86400000);
  },
  close() {
    this.showBanner = false;
  },
  getOpenAppLink() {
    const appStoreLinks = {
      android: 'https://play.google.com/store/apps/details?id=dk.releaze.tv2regionerne.tvsyd&hl=da',
      ios: 'https://apps.apple.com/dk/app/tv-syd-nyheder/id460978648',
    }

    return appStoreLinks[this.currentDevice];
  },
  trackClickEvent(action_taken) {
    plausible('app_banner_clicked', {props: {device: this.currentDevice, banner_action: action_taken}})
  }
}));
