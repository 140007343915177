import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('app_request_temp', (disable_endless) => ({
  disableEndless: disable_endless,
  isAppRequest2() {
    return this.disableEndless || this.isAppRequest();
  },
  isAppRequest () { 
    return window.navigator.userAgent.indexOf('median') !== -1;
  }
}));
